var exports = {};
exports.__esModule = true;
exports.LinesAndColumns = void 0;
var LF = "\n";
var CR = "\r";

var LinesAndColumns = function () {
  function LinesAndColumns(string) {
    this.string = string;
    var offsets = [0];

    for (var offset = 0; offset < string.length;) {
      switch (string[offset]) {
        case LF:
          offset += LF.length;
          offsets.push(offset);
          break;

        case CR:
          offset += CR.length;

          if (string[offset] === LF) {
            offset += LF.length;
          }

          offsets.push(offset);
          break;

        default:
          offset++;
          break;
      }
    }

    this.offsets = offsets;
  }

  LinesAndColumns.prototype.locationForIndex = function (index) {
    if (index < 0 || index > this.string.length) {
      return null;
    }

    var line = 0;
    var offsets = this.offsets;

    while (offsets[line + 1] <= index) {
      line++;
    }

    var column = index - offsets[line];
    return {
      line: line,
      column: column
    };
  };

  LinesAndColumns.prototype.indexForLocation = function (location) {
    var line = location.line,
        column = location.column;

    if (line < 0 || line >= this.offsets.length) {
      return null;
    }

    if (column < 0 || column > this.lengthOfLine(line)) {
      return null;
    }

    return this.offsets[line] + column;
  };

  LinesAndColumns.prototype.lengthOfLine = function (line) {
    var offset = this.offsets[line];
    var nextOffset = line === this.offsets.length - 1 ? this.string.length : this.offsets[line + 1];
    return nextOffset - offset;
  };

  return LinesAndColumns;
}();

exports.LinesAndColumns = LinesAndColumns;
exports["default"] = LinesAndColumns;
export default exports;
export const __esModule = exports.__esModule;
const _LinesAndColumns = exports.LinesAndColumns;
export { _LinesAndColumns as LinesAndColumns };